<template>
  <div class="updata_box">
    <div style="width: 100%;height: 50px;"></div>
    <div class="updata_box_cenete">
      <div class="updata_div">
        <div class="updata_div_toptext">上传作品</div>
        <img src="../../../../static/wenchang/9.png" alt="">
        <div class="input1" style="padding-top: 109px;">
          <div style="margin-right: 42px">
            <img src="../../../../static/wenchang/10.png" alt="">
          </div>
          <div style="width: 883px;">
            <el-input v-model="artworkTitle" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="select1" style="padding-top: 84px;">
          <div class="input2">
            <div style="margin-right: 42px;margin-left: 30px;">
              <img src="../../../../static/wenchang/11.png" alt="">
            </div>
            <div>
              <el-select v-model="actualType" placeholder="请选择">
                <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="input2">
            <div style="margin-right: 42px">
              <img src="../../../../static/wenchang/12.png" alt="">
            </div>
            <div>
              <el-select v-model="artworkType" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="input2" style="padding-top: 84px;">
          <div style="margin-right: 42px">
            <img src="../../../../static/wenchang/13.png" alt="">
          </div>
          <div style="width: 883px;">
            <el-input v-model="artworkClarification" type="textarea" :rows="10" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="select1" style="padding-top: 84px;">
          <div class="input2">
            <div style="margin-right: 41px">
              <img src="../../../../static/wenchang/14.png" alt="">
            </div>
            <div>
              <el-input v-model="authorName" placeholder="请输入"></el-input>
            </div>
          </div>
          <div class="input2">
            <div style="margin-right: 42px;margin-left: 52px;">
              <img src="../../../../static/wenchang/15.png" alt="">
            </div>
            <div>
              <el-input v-model="email" placeholder="请输入"></el-input>
            </div>
          </div>
        </div>
        <div class="select1" style="padding-top: 84px;">
          <div class="input2">
            <div style="margin-right: 39px;margin-left: 74px;">
              <img src="../../../../static/wenchang/16.png" alt="">
            </div>
            <div>
              <el-input v-model="phone" :disabled="true" placeholder="请输入"></el-input>
            </div>
          </div>
          <div class="input2">
            <div style="margin-right: 42px">
              <img src="../../../../static/wenchang/17.png" alt="">
            </div>
            <div>
              <el-input v-model="source" placeholder="请输入"></el-input>
            </div>
          </div>
        </div>
        <div class="input2" style="padding-top: 84px;">
          <div style="margin-right: 35px;margin-left: 30px;">
            <img src="../../../../static/wenchang/18.png" alt="">
          </div>
          <div style="width: 883px;">
            <el-input v-model="recommend" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="select2" style="padding-top: 84px;">
          <div class="input3">
            <div style="margin-right: 40px;">
              <img src="../../../../static/wenchang/19.png" alt="">
            </div>
            <div>
              <el-upload :action="updataurl" list-type="picture-card" :file-list="filecover" ref="uploadRef" :auto-upload="false" accept=".JPG, .PNG" :on-success="handlePicture" :on-change="beforeUpload">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <!-- <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                      <i class="el-icon-download"></i>
                    </span> -->
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
          </div>
        </div>
        <div class="select2" style="padding-top: 14px;">
          <div class="input3">
            <div style="margin-right: 40px;margin-left: 27px;">
              <img src="../../../../static/wenchang/20.png" alt="">
            </div>
            <div>
              <el-upload :action="updataurl" list-type="picture-card" :file-list="filepictures" ref="uploadRefs" :auto-upload="false" :on-change="beforeUploads" :on-success="handlePictures" accept=".JPG, .PNG" :on-exceed="handleExceed" :limit="limit">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreviews(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <!-- <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownloads(file)">
                      <i class="el-icon-download"></i>
                    </span> -->
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemoves(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
          </div>
          <div class="input3uplodtxt">请上传JPG或PNG格式，每件或每组作品不超过9张（含展板）</div>
        </div>
        <div class="select2" style="padding-top: 14px;">
          <div class="input3">
            <div style="margin-right: 40px;margin-left: 27px;">
              <img src="../../../../static/wenchang/21.png" alt="">
            </div>
            <div>
              <!-- 视频上传 -->
              <el-upload class="upload-demo" :action="updataurl" ref="uploadRefsss" :limit="1" :on-progress="handleProgress" :on-success="handlePicturess" :file-list="video" :on-exceed="handleerror" :on-remove="shanchuship" :auto-upload="false" :on-change="beforeUploadss" accept=".AVI, .MOV, .WMV, .MP4">
                <el-button size="small" type="primary">点击上传</el-button>
                <template #tip>
                  <div slot="tip" class="el-upload__tip">请上传AVI、MOV、WMV、MP4格式视频</div>
                  <div style="font-size: 15px;margin-top: 10px;" v-if="uplode">
                    正在上传中请稍等(文件大小影响上传速度)···
                  </div>
                </template>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="select2" style="padding-top: 14px;">
          <div class="input3">
            <div style="margin-right: 40px;margin-left: -24px;">
              <img src="../../../../static/wenchang/22.png" alt="">
            </div>
            <div>
              <!-- 文件上传 -->
              <el-upload class="upload-demo" :action="updataurl" ref="uploadRefssss" :limit="1" :on-success="handlePicturesss" :file-list="file" :on-exceed="handleerror" :auto-upload="false" :on-remove="shanchuwenjian" :on-change="beforeUploadsss" accept=".*">
                <el-button size="small" type="primary">点击上传</el-button>
                <template #tip>
                  <div slot="tip" class="el-upload__tip">请上传文件(仅限于zip,rar,pdf,word,ppt)</div>
                  <div style="font-size: 15px;margin-top: 10px;" v-if="uplode">
                    正在上传中请稍等(文件大小影响上传速度)···
                  </div>
                </template>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="submits">
          <div>
            <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
          </div>
          <div style="color: #187AC2;cursor: pointer;" @click="gotolink()">
            《原创声明》</div>
          <div style="color: #187AC2;cursor: pointer;" @click="gotolink2()">
            《参赛声明》</div>
        </div>
        <!-- <div style="color: red;font-size: 15px; text-align: center;">注：非第一次提交会覆盖上一次的数据！</div> -->
        <div class="submit" @click="submit" v-loading="uplode">
          <img src="../../../../static/wenchang/提交.png" alt="">
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 50px;"></div>
    <img class="updata_bottom" src="../../../../static/wenchang/4.png" alt="">
  </div>
</template>

<script>
import { artworkadd, artworkselectOne, getosstoken } from "../../../util/api"
const OSS = require('ali-oss')
export default {
  name: 'science_child2',

  data() {
    return {
      uplode: false,
      // updataurl: "https://wenchuang-web.123bingo.cn/api/file/uploadImg",
      // 已改成oss直接上传
      updataurl: "http://localhost:8080/api/file/uploadImg",
      // http://localhost:8080/
      //https://wenchuang-web.123bingo.cn/api/file/uploadImg
      artworkTitle: '',
      actualType: '',
      artworkType: '',
      artworkClarification: '',
      authorName: '',
      email: '',
      phone: localStorage.getItem('phone'),
      recommend: '',
      source: '',
      video: [],
      cover: '',
      filecover: [],
      filepictures: [],
      pictures: [],
      file: [],
      checked: false,
      progressPercent: 0,
      limit: 9,
      options: [{
        value: '城市礼品',
        label: '城市礼品'
      }, {
        value: '器物包装',
        label: '器物包装'
      }, {
        value: '美妆服饰',
        label: '美妆服饰'
      }, {
        value: '家居用品',
        label: '家居用品'
      }
        , {
        value: '文具产品',
        label: '文具产品'
      }, {
        value: '生活用品',
        label: '生活用品'
      }
        , {
        value: '娱乐设备',
        label: '娱乐设备'
      }, {
        value: '运动器具',
        label: '运动器具'
      }, {
        value: '餐饮器皿',
        label: '餐饮器皿'
      }, {
        value: '其他作品',
        label: '其他作品'
      }
      ],
      options2: [{
        value: '创意设计组',
        label: '创意设计组'
      }, {
        value: '文创产品组',
        label: '文创产品组'
      }],
      input: '',
      value: '',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      avctas: 1,
      id: '',
      supststoken: {}
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getdatalist()
    }
    this.ststoken()
  },
  methods: {
    shanchuship() {
      this.video = []
    },
    shanchuwenjian() {
      this.file = []
    },
    async ststoken() {
      getosstoken().then((res) => {
        this.supststoken = res.data.credentials;
      })
    },
    ossupdata(file) {
      const client = new OSS({
        // region填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: 'oss-cn-hangzhou',
        accessKeyId: this.supststoken.accessKeyId,
        accessKeySecret: this.supststoken.accessKeySecret,
        stsToken: this.supststoken.securityToken,
        bucket: 'wenchuang001'
      })
      var currentDate = new Date();
      var timestamp = Math.floor(currentDate.getTime() / 1000);
      const str = file.name.replace('+', '')
      client.multipartUpload('test/' + timestamp + '_' + str, file).then((res) => {
        console.log(res);
        this.cover = 'https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/' + res.name
      })
    },
    beforeUploadsss(file) {
      console.log(file);
      this.uplode = true;
      const client = new OSS({
        // region填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: 'oss-cn-hangzhou',
        accessKeyId: this.supststoken.accessKeyId,
        accessKeySecret: this.supststoken.accessKeySecret,
        stsToken: this.supststoken.securityToken,
        bucket: 'wenchuang001'
      })
      var currentDate = new Date();
      var timestamp = Math.floor(currentDate.getTime() / 1000);
      const str = file.name.replace('+', '')
      client.multipartUpload('test/' + timestamp + '_' + str, file.raw).then((res) => {
        console.log(res.name);
        this.uplode = false
        const onj = {
          url: 'https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/' + res.name,
          name: res.name
        }
        this.file = []
        this.file.push(onj)
      })
    },
    beforeUploadss(file) {
      console.log(file);
      this.uplode = true;
      const client = new OSS({
        // region填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: 'oss-cn-hangzhou',
        accessKeyId: this.supststoken.accessKeyId,
        accessKeySecret: this.supststoken.accessKeySecret,
        stsToken: this.supststoken.securityToken,
        bucket: 'wenchuang001'
      })
      var currentDate = new Date();
      var timestamp = Math.floor(currentDate.getTime() / 1000);
      const str = file.name.replace('+', '')
      client.multipartUpload('test/' + timestamp + '_' + str, file.raw).then((res) => {
        console.log(res.name);
        this.uplode = false
        const onj = {
          url: 'https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/' + res.name,
          name: res.name
        }
        this.video = []
        this.video.push(onj)
      })
    },
    handleExceed() {
      this.$message.warning(`最多只能上传 ${this.$refs.uploadRefs.limit} 个文件！`);
    },
    beforeUploads(file) {
      console.log(this.pictures);
      this.uplode = true;
      const client = new OSS({
        // region填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: 'oss-cn-hangzhou',
        accessKeyId: this.supststoken.accessKeyId,
        accessKeySecret: this.supststoken.accessKeySecret,
        stsToken: this.supststoken.securityToken,
        bucket: 'wenchuang001'
      })
      var currentDate = new Date();
      var timestamp = Math.floor(currentDate.getTime() / 1000);
      const str = file.name.split("+").join("")
      console.log(str);
      client.multipartUpload('test/' + timestamp + '_' + str, file.raw).then((res) => {
        this.uplode = false
        this.pictures.push('https://wenchuang001.oss-cn-hangzhou.aliyuncs.com/' + res.name);
      })
    },
    handleProgress(event) {
      this.progressPercent = event.percent
      this.uplode = true;
    },
    getdatalist() {
      artworkselectOne({ id: this.$route.query.id }).then((res) => {
        console.log(res.data);
        this.artworkTitle = res.data.artworkTitle
        this.actualType = res.data.actualType
        this.artworkType = res.data.artworkType
        this.artworkClarification = res.data.artworkClarification
        this.authorName = res.data.authorName
        this.email = res.data.email
        this.recommend = res.data.recommend
        this.source = res.data.source
        this.workUnit = res.data.workUnit
        const obj = {
          url: res.data.cover,
          uid: 1
        }
        this.filecover.push(obj)
        this.cover = res.data.cover
        let arr = []
        if (res.data.pictures) {
          arr = res.data.pictures.split(',')
          arr.forEach((element, i) => {
            const obj2 = {
              url: element,
              uid: i,
            }
            this.filepictures.push(obj2)
            this.pictures.push(element)
          });
        }
        const namearrs = res.data.video.split('_')
        const obj3 = {
          url: res.data.video,
          name: namearrs[1],
        }
        const namearr = res.data.file.split('_')
        const obj4 = {
          url: res.data.file,
          name: namearr[1],
        }
        if (res.data.video) {
          this.video.push(obj3)
        }
        if (res.data.file) {
          this.file.push(obj4)
        }
        this.id = res.data.id
      })
    },
    gotolink2() {
      this.$router.push('/science_child6')
    },
    gotolink() {
      this.$router.push('/science_child5')
    },
    fenye() {
      this.$router.push('/science_child4')
    },
    fenyes() {
      this.$router.push('/culture')
    },
    submit() {
      // this.$message({
      //   message: '报名已经结束，无法上传作品!',
      //   type: 'warning'
      // });
      if (!this.checked) {
        this.$message({
          message: '请先勾选原创声明！',
          type: 'warning'
        });
        return
      }
      if (this.artworkTitle == '' || this.artworkTitle == null) {
        this.$message({
          message: '请填写作品标题！',
          type: 'warning'
        });
        return
      }
      if (this.artworkType == '' || this.artworkType == null) {
        this.$message({
          message: '请填写作品类别！',
          type: 'warning'
        });
        return
      }
      if (this.artworkClarification == '' || this.artworkClarification == null) {
        this.$message({
          message: '请填写作品说明！',
          type: 'warning'
        });
        return
      }
      if (this.authorName == '' || this.authorName == null) {
        this.$message({
          message: '请填写作者姓名！',
          type: 'warning'
        });
        return
      }
      if (this.cover == '' || this.cover == null) {
        this.$message({
          message: '上传作品封面！',
          type: 'warning'
        });
        return
      }
      console.log(this.video);
      this.uplode = true;
      artworkadd({
        artworkTitle: this.artworkTitle,
        actualType: this.actualType,
        artworkType: this.artworkType,
        artworkClarification: this.artworkClarification,
        authorName: this.authorName,
        email: this.email,
        phone: this.phone,
        recommend: this.recommend,
        source: this.source,
        video: this.video[0] ? this.video[0].url : '',
        cover: this.cover,
        pictures: this.pictures + '',
        file: this.file[0] ? this.file[0].url : '',
        userId: localStorage.getItem('useinfo'),
        id: this.id
      }).then((res) => {
        if (res.data.message) {
          this.$message({
            message: res.data.message,
            type: 'warning'
          });
          this.uplode = false;
        } else {
          this.$message({
            message: '提交成功！',
            type: 'success'
          });
          this.uplode = false;
          this.$router.push('/science_child3')
        }
      })
    },
    handleChange(fileList) {
      this.fileList = fileList.slice(-3);
    },
    handleerror() {
      this.$message({
        message: '已有上传文件！',
        type: 'warning'
      });
    },
    handlePicturesss(file) {
      this.uplode = false
      const { uploadFiles } = this.$refs.uploadRefssss
      const onj = {
        url: file.imgPath,
        name: uploadFiles[0].name
      }
      this.file = []
      this.file.push(onj)
    },
    handlePicturess(file) {
      this.uplode = false
      const { uploadFiles } = this.$refs.uploadRefsss
      const onj = {
        url: file.imgPath,
        name: uploadFiles[0].name
      }
      this.video = []
      this.video.push(onj)
    },
    handlePictureCardPreviewss(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePictures(file) {
      this.pictures.push(file.imgPath)
    },
    handleRemoves(file) {
      console.log(file);
      const { uploadFiles } = this.$refs.uploadRefs
      console.log(uploadFiles);
      if (uploadFiles && uploadFiles.length) {
        // uploadFiles.splice(uploadFiles.length - 1, 1)
        uploadFiles.forEach((item, index) => {
          if (item.uid == file.uid) {
            uploadFiles.splice(index, 1)
          }
        })

        this.pictures.forEach((item, index) => {
          if (item == file.url) {
            this.pictures.splice(index, 1)
          }
        })

      }
    },
    handlePictureCardPreviews(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      console.log(file);
      this.cover = ''
      const { uploadFiles } = this.$refs.uploadRef
      if (uploadFiles && uploadFiles.length) {
        uploadFiles.splice(uploadFiles.length - 1, 1)
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    dataURLToBlob(fileDataURL) {
      let arr = fileDataURL.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    beforeUpload(file) {
      console.log(file);
      this.ossupdata(file.raw)
      const arr = []
      arr.push(file)
      this.$refs.uploadRef.uploadFiles = arr
    },
    handlePicture(file) {
      console.log(file);
      this.cover = file.imgPath
    },
  }
}
</script>
<style scoped>
.submits {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-bottom: 12px;
  padding-top: 50px;
}

.submit {
  width: 100%;
  text-align: center;
  padding-top: 50px;
  cursor: pointer;
  padding-bottom: 20px;
}

.updata_bottom {
  /* position: fixed;
  bottom: 0 */
  width: 100%;
  margin-bottom: -6px;
}

.updata_box_cenete {
  margin: 61px auto;
  width: 1193px;
  /* height: 1000px; */
  background: #ffffff;
  border-radius: 30px;
}

.updata_box {
  background-image: url("../../../../static/wenchang/8.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.updata_div {
  position: relative;
  padding: 0 71px;
  padding-top: 51px;
}

.updata_div_toptext {
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  color: #333333;
}

.input1 {
  width: 100%;
  display: flex;
  align-items: center;
}

.input2 {
  width: 100%;
  display: flex;
  align-items: center;
}

.input3 {
  width: 100%;
  display: flex;
}

.select1 {
  display: flex;
  align-items: center;
}

.select2 {
}

.input3uplodtxt {
  font-weight: 100;
  font-size: 14px;
  color: #f34242;
  margin-top: 20px;
  margin-left: 152px;
}

.el-upload__tip {
  font-size: 14px;
  color: #f34242;
}
</style>